<template>
  <!-- <div class="live-main-manager">
    <LiveIndex />
  </div> -->
  <LiveIndex />
</template>

<script>
import LiveIndex from '@/views/live/Index.vue';
import Permissions from '@/router/permissions';

export default {
  name: 'Live',
  components: {
    LiveIndex,
  },
  mounted() {
    if (Permissions.isRole(this.$store.state.account.role, '/live/manage')) {
      console.log('IndexManager');
      this.$live.setup(this);
    } else {
      this.$router.push('/403');
    }
  },
};
</script>

<style lang="scss" scoped></style>
